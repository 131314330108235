import { parse } from "query-string";

const params = parse(location.search);
const isMaster = !!params.master,
  consoleEnabled = !!params.console;

const accessToken = params.accessToken || "00000005-gTMP4z5";
const secretToken = params.secretToken || "58FKfQFHVMHZkjtvqXgprytAzVVPNoa6";
const mediaElement = "#media";
const $log = document.querySelector("#log");
const $pos = document.querySelector("#pos");

let player: IPlayer, handler;

interface Song {
  url: string;
  title: string;
}

interface Media {
  artistId: number;
  artistName: string;
  name: string;
  [key: string]: any;
}

interface SyncPlugin {
  readonly isMaster: boolean;
  readonly isSlave: boolean;
  readonly accessToken: string;
  readonly secretToken: string;
  readonly clockWorker: any;
  readonly stageWorker: any;
  readonly stateWorker: {
    __enterStates__: any[];
    __leaveStates__: any[];
    [key: string]: any;
  };
  readonly historyWorker: any;
  [key: string]: any;
}

interface PlaylistItemBase {
  mediaSourceUrl: string;
}

interface PlaylistItem extends PlaylistItemBase {
  index: number;
  relativeStartTimeMs: number;
  relativeEndTimeMs: number;
  durationTimeMs: number;
  // mediaRelativeStartTimeMs: number;
  // mediaRelativeEndTimeMs: number;
  // mediaDurationTimeMs: number;
  readonly next: PlaylistItem;
  readonly prev: PlaylistItem;
  [key: string]: any;
}

interface IPlayer {
  play();
  pause();
  seekTo(position: number);
  setPlaylist(playlist: PlaylistItemBase[]);
  stop();
  position: number;
  playlist: {
    items: PlaylistItem[];
    currentItem: PlaylistItem;
    absoluteStartTime: number;
    durationTime: number;
    positionTime: number;
  };
  on(
    action: "ready",
    listener: (ev: {
      target: IPlayer;
      type: "ready";
      data: { media: Media };
    }) => void
  ): void;
  on(
    action: "play",
    listener: (ev: { target: Media; type: "play" }) => void
  ): void;
  on(
    action: "pause",
    listener: (ev: { target: Media; type: "pause" }) => void
  ): void;
  on(
    action: "finish",
    listener: (ev: { target: Media; type: "finish" }) => void
  ): void;

  on(
    action: "songleSyncStageAbsoluteStartTimeUpdate",
    listener: (ev: {
      target: SyncPlugin;
      type: "songleSyncStageAbsoluteStartTimeUpdate";
      data: { stageAbsoluteStartTime: number };
    }) => void
  ): void;
  on(
    action: "songleSyncStageAbsolutePauseTimeUpdate",
    listener: (ev: {
      target: SyncPlugin;
      type: "songleSyncStageAbsolutePauseTimeUpdate";
      data: { stageAbsolutePauseTime: number };
    }) => void
  ): void;

  on(
    action: "songleSyncPlaylistUpdate",
    listener: (ev: {
      target: SyncPlugin;
      type: "songleSyncPlaylistUpdate";
      data: { playlistItems: PlaylistItem[] };
    }) => void
  ): void;
  on(
    action: "songleSyncPlaylistFinish",
    listener: (ev: {
      target: SyncPlugin;
      type: "songleSyncPlaylistFinish";
    }) => void
  ): void;

  on(
    action: "songleSyncPlaylistItemEnter",
    listener: (ev: {
      target: SyncPlugin;
      type: "songleSyncPlaylistItemEnter";
      data: { playlistItem: PlaylistItem };
    }) => void
  ): void;
  on(
    action: "songleSyncPlaylistItemLeave",
    listener: (ev: {
      target: SyncPlugin;
      type: "songleSyncPlaylistItemLeave";
      data: { playlistItem: PlaylistItem };
    }) => void
  ): void;

  [key: string]: any;
}

const playlist: Song[] = [
  { url: "http://www.youtube.com/watch?v=Za3SaGnFCFI", title: "Awake" },
  { url: "http://www.youtube.com/watch?v=5Cof9rP7TEQ", title: "in the Garden" },
  {
    url: "http://www.youtube.com/watch?v=xOKplMgHxxA",
    title: "ハッピーシンセサイザ"
  }
];

function onSongleAPIReady(S: any) {
  // S.System.showDebugLog = true; // デバッグモード
  S.System.showErrorLog = true; // エラー表示

  // create a player instance
  const { SyncPlayer } = S;
  player = new SyncPlayer(
    isMaster
      ? {
          accessToken,
          secretToken,
          mediaElement
        }
      : {
          accessToken,
          mediaElement
        }
  );

  // get beats and choruses (choruses are required to populate Songle Widget)
  player.addPlugin(new S.Plugin.Beat());
  player.addPlugin(new S.Plugin.Chorus());

  // add Songle Widget
  const songleWidgetPlugin = new S.Plugin.SongleWidget({
    element: "#widget",
    height: 40,
    // showController: false,
    showOriginalSiteLink: false,
    showSongleJpSiteLink: false
  });
  player.addPlugin(songleWidgetPlugin);

  // set playlist on app launch
  // FIXME: 同期的に呼び出すと resizeTrap で例外発生
  if (isMaster) {
    setTimeout(() => {
      player.setPlaylist(
        playlist.map(item => {
          return {
            mediaSourceUrl: item.url
          };
        })
      );
    }, 1);
  }

  player.on("play", () => {
    if (handler) return;
    handler = setInterval(polling, 100);
  });
  player.on("pause", () => {
    if (!handler) return;
    clearInterval(handler);
    handler = null;
  });

  player.on("ready", ({ data }) => {
    append(`ready: ${data.media.name} (${data.media.artistName})`);
  });
  player.on("play", ({ type }) => append(type));
  player.on("pause", ({ type }) => append(type));
  player.on("finish", ({ type }) => append(type));
  player.on("songleSyncPlaylistUpdate", ({ type, data }) => {
    append(
      type +
        ": " +
        data.playlistItems
          .map(item => {
            const it = playlist.find(pi => pi.url === item.mediaSourceUrl);
            return it ? it.title : "-";
          })
          .join(", ")
    );
  });
  player.on("songleSyncStageAbsoluteStartTimeUpdate", ({ type, data }) =>
    append(type + ": " + data.stageAbsoluteStartTime)
  );
  player.on("songleSyncStageAbsolutePauseTimeUpdate", ({ type, data }) =>
    append(type + ": " + data.stageAbsolutePauseTime)
  );
  player.on("songleSyncPlaylistItemEnter", ({ type }) => {
    const item = player.playlist.currentItem;
    const it = playlist.find(pi => pi.url === item.mediaSourceUrl);
    append(`${type}: ${item.index} (${it ? it.title : "-"})`);
  });
  player.on("songleSyncPlaylistItemLeave", ({ type }) => {
    const item = player.playlist.currentItem;
    const it = playlist.find(pi => pi.url === item.mediaSourceUrl);
    append(`${type}: ${item.index} (${it ? it.title : "-"})`);
  });
  player.on("songleSyncPlaylistFinish", ({ type }) => append(type));

  if (consoleEnabled) {
    player.on("ready", ready);
    player.on("play", play);
    player.on("pause", pause);
    player.on("finish", finish);
    player.on("songleSyncPlaylistUpdate", songleSyncPlaylistUpdate);
    player.on(
      "songleSyncStageAbsoluteStartTimeUpdate",
      songleSyncStageAbsoluteStartTimeUpdate
    );
    player.on(
      "songleSyncStageAbsolutePauseTimeUpdate",
      songleSyncStageAbsolutePauseTimeUpdate
    );
    player.on("songleSyncPlaylistItemEnter", songleSyncPlaylistItemEnter);
    player.on("songleSyncPlaylistItemLeave", songleSyncPlaylistItemLeave);
    player.on("songleSyncPlaylistFinish", songleSyncPlaylistFinish);
  }
}

function playerAction(func: (p: any) => void) {
  return (e: MouseEvent) => {
    e.preventDefault();
    if (player) {
      func(player);
    }
    return false;
  };
}

if (isMaster) {
  (document.querySelector(
    ".actions.play"
  ) as HTMLButtonElement).onclick = playerAction(p => p.play());
  (document.querySelector(
    ".actions.pause"
  ) as HTMLButtonElement).onclick = playerAction(p => p.pause());
  (document.querySelector(
    ".actions.prev"
  ) as HTMLButtonElement).onclick = playerAction(p =>
    p.seekToPrevPlaylistItem()
  );
  (document.querySelector(
    ".actions.next"
  ) as HTMLButtonElement).onclick = playerAction(p =>
    p.seekToNextPlaylistItem()
  );
} else {
  document.querySelectorAll(".actions").forEach(el => {
    (el as HTMLButtonElement).disabled = true;
  });
}

function append(text: string) {
  const p = document.createElement("p");
  p.appendChild(document.createTextNode(text));
  $log.appendChild(p);
}

function polling() {
  if (!player) return;
  ($pos.childNodes.item(0) as Text).data = String(player.position);
}

function ready() {
  console.log("ready", arguments);
}
function play() {
  console.log("play", arguments);
}
function pause() {
  console.log("pause", arguments);
}
function seek() {
  console.log("seek", arguments);
}
function finish() {
  console.log("finish", arguments);
}
function songleSyncStageAbsoluteStartTimeUpdate() {
  console.log("songleSyncStageAbsoluteStartTimeUpdate", arguments);
}
function songleSyncStageAbsolutePauseTimeUpdate() {
  console.log("songleSyncStageAbsolutePauseTimeUpdate", arguments);
}
function songleSyncPlaylistUpdate() {
  console.log("songleSyncPlaylistUpdate", arguments);
}
function songleSyncPlaylistItemEnter() {
  console.log("songleSyncPlaylistItemEnter", arguments);
}
function songleSyncPlaylistItemLeave() {
  console.log("songleSyncPlaylistItemLeave", arguments);
}
function songleSyncPlaylistFinish() {
  console.log("songleSyncPlaylistFinish", arguments);
}

self["onSongleAPIReady"] = onSongleAPIReady;
